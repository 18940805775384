import 'firebaseui/dist/firebaseui.css';
import './AdminLogin.css';
import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useNotify, useRedirect } from 'react-admin';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { AuthProvider as firebaseAuthProvider } from '../../providers/FirebaseAuthProvider';
import firebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';
import { config } from '../../firebase/firebase';

import Logo from '../../images/Header/SledTRXLogo.png';

const AdminLogin = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(() => {
        const uiConfig = {
            signInFlow: 'popup',
            signInSuccessUrl: '/admin/trails',
            signInOptions: [
                GoogleAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                signInSuccessWithAuthResult: (result) => {
                    const user = result.user;
        
                    const authProvider = firebaseAuthProvider(config);
    
                    const dataProvider = firebaseRealtimeDatabaseProvider();
        
                    dataProvider.getOne('users', { id: result.user.uid })
                        .then((result) => {
                            if (result.data?.type !== 'admin') {
                                authProvider.logout();
                                notify('You are not allowed to access this area');
    
                                var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(getAuth());
                                ui.start('#firebaseui-auth-container', uiConfig);
                            } else {
                                redirect('/admin');
                            }
                        });
                }
            }
        };

        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(getAuth());
        ui.start('#firebaseui-auth-container', uiConfig);

        //Set the page title
        document.title = 'Log In | SledTRX';
    }, []);

    return (
        <Stack id="AdminLogin" flex={1} spacing={8} alignItems="center" justifyContent="center">
            <img src={Logo} alt="SledTRX Logo" sx={{ width: '400px' }} className="logo"></img>
            <div id="firebaseui-auth-container"></div>
        </Stack>
    );
};

export default AdminLogin;