import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../firebase/auth';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

const AccountUpdate = () => {
    const { authUser, isLoading, refreshAuthUser } = useAuth();
    const navigate = useNavigate();

    const [accountInformation, setAccountInformation] = useState({});

    const dataProvider = FirebaseRealtimeDatabaseProvider();

    //Save the updates to the database and redirect
    const updateProfile = () => {
        const updatedInformation = {
            ...authUser,
            name: accountInformation.name ?? '',
            town: accountInformation.town ?? ''
        };
        dataProvider.update('users', { id: authUser.id, data: updatedInformation })
            .then(() => {
                refreshAuthUser().then(() => {
                    navigate('/Account/Profile');
                });
            });
    };

    //Set the page title
    useEffect(() => {
        document.title = 'Update Your Profile | SledTRX';
    }, []);

    return (
        <Stack spacing={4} sx={{ height: { xs: 'auto', sm: '100%' } }}>
            <Typography variant="h1" sx={{ fontSize: 36, fontWeight: 'bold' }}>Update Your Profile</Typography>
            <Stack spacing={4} sx={{ flexGrow: 1 }}>
                { isLoading ? 
                    <CircularProgress /> :
                    <>
                        <TextField
                            label="Your Name"
                            defaultValue={authUser?.name}
                            onChange={(e) => setAccountInformation(ai => ({
                                ...ai,
                                name: e.target.value
                            }))}
                        />
                        <TextField
                            label="Town"
                            defaultValue={authUser?.town}
                            onChange={(e) => setAccountInformation(ai => ({
                                ...ai,
                                town: e.target.value
                            }))}
                        />
                    </>
                }
            </Stack>
            <Stack direction="row" sx={{ justifyContent: 'end' }}>
                <Button variant="contained" onClick={() => updateProfile()}>Update Profile</Button>
            </Stack>
        </Stack>
    );
};

export default AccountUpdate;