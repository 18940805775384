import SnowmobileIcon from '@mui/icons-material/Snowmobile';
import ClubCreate from './ClubCreate';
import ClubEdit from './ClubEdit';
import ClubList from './ClubList';

const clubs = {
    list: ClubList,
    create: ClubCreate,
    edit: ClubEdit,
    icon: SnowmobileIcon,
};

export default clubs;