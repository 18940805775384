import './TermsOfService.css';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const TermsOfService = ({ standalone }) => {
    return (
        <div class="tos">
            <h1>
                SledTRX Terms of Service & Private Policy
            </h1>

            <h2>
                Terms of Service
            </h2>

            <p>
                By using the SledTRX website or mobile application (collectively, the "Services"), you agree to abide by these Terms of Service. Please read these terms carefully as they contain important information regarding your use of our Services. If you do not agree with any part of these Terms, you should not use our Services.
            </p>
            <ol>
                <li>
                    Intellectual Property: You acknowledge that all intellectual property and proprietary rights, including copyrights, patents, trademarks, source code, and trade secrets, in and to the Services are owned by SledTRX, our affiliates, or licensors. Your use of the Services does not transfer any right, title, or interest in or to any such intellectual property or proprietary right. SledTRX retains full and complete title to all Content, including software and data.
                </li>
                <li>
                    Mapping and Trail Data: Mapping and trail data provided by SledTRX are intended for use exclusively within the Services and must not be used by anyone or any group other than SledTRX LLC without our explicit permission.
                </li>
                <li>
                    Liability and Responsibility:
                    <ol type="a">
                        <li>
                            SledTRX is not responsible for the behavior of its users or any personal injury, death, property damage, or other harm or losses arising from or related to the use of the Services.
                        </li>
                        <li>
                            The Services do not provide insurance for your product or otherwise. You are responsible for obtaining appropriate insurance coverage for any risk of loss, including property or medical losses.
                        </li>
                    </ol>
                </li>
                <li>
                    Availability and Connectivity:
                    <ol type="a">
                        <li>
                            Some SledTRX features require wireless communication networks and global positioning system ("GPS") satellite networks to function. These features may not be available everywhere or on all relevant products at all times.
                        </li>
                        <li>
                            You are responsible for all fees charged by your internet service provider (ISP) and carrier in connection with your use of the Services. You must also comply with all applicable agreements, terms of use/service, and other policies of your ISP and carrier.
                        </li>
                    </ol>
                </li>
                <li>
                    Third-Party Platforms: Google and Apple are not parties to these Terms. If you download our mobile apps or use any product, service, platform, content, or software provided by Google or Apple, you must agree to their respective terms and conditions, including those of the Google Play Store or the Apple App Store.
                </li>
                <li>
                    Emergency Response and Responsibility:
                    <ol type="a">
                        <li>
                            You are responsible for educating yourself on how to respond to an emergency and must respond according to the specifics of your situation.
                        </li>
                        <li>
                            Users must take responsibility for any illegal actions, such as trespassing or traveling on marked/private land.
                        </li>
                        <li>
                            Users are responsible for any issues or problems encountered while using SledTRX's map and mapping tools, both on our website and mobile application.
                        </li>
                        <li>
                            SledTRX data may change, and it is the user's responsibility to verify trail and map information.
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>
                Private Policy
            </h2>
            <p>
                At SledTRX, we are committed to protecting your privacy and ensuring that your personal information is handled with care and respect. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our services. By using our services, you consent to the practices described in this policy.
            </p>
            <h3>
                Information We Collect:
            </h3>
            <ul>
                <li>
                    Profile Information: When you use our services, we may collect certain profile information, such as your name, email address, and other information you provide.
                </li>
                <li>
                    Duration Spent on Services: We may track the amount of time you spend using our services for internal analysis purposes, including evaluating the progress and performance of our services.
                </li>
            </ul>
            <h3>
                How We Use Your Information:
            </h3>
            <ul>
                <li>
                    Internal Analysis: We use your profile information and data on the duration spent on our services to analyze user engagement, evaluate the performance of our services, and make improvements.
                </li>
                <li>
                    Payment Information: If you subscribe to our services, your payment information will be managed securely within the Apple App Store or Google Play Store. We do not have access to or store your payment information.
                </li>
                <li>
                    Trail Data: Any trail data and information provided within our services are strictly for use within the SledTRX organization. We do not permit the use of this data outside our organization.
                </li>
            </ul>
            <h3>
                Data Security:
            </h3>
            <p>
                We take data security seriously and employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. While we make every effort to protect your data, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
            </p>
            <h3>
                Third-Party Services:
            </h3>
            <p>
                Our services may contain links to third-party websites or services. Please be aware that we are not responsible for the privacy practices of these third parties. We encourage you to review the privacy policies of any third-party services you access through our platform.
            </p>
            <h3>
                Changes to this Privacy Policy:
            </h3>
            <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on our website, and the effective date will be revised accordingly. We encourage you to review this policy periodically for any changes.
            </p>
            <p class="strong">
                By using our services, you agree to the terms and conditions of this Privacy Policy. Thank you for choosing SledTRX.
            </p>
            <p>
                Last Updated: 10/2/23
            </p>
        </div>
    );
};

export default TermsOfService;