import ArticleIcon from '@mui/icons-material/Article';
import PageCreate from './PageCreate';
import PageEdit from './PageEdit';
import PageList from './PageList';

const pages = {
    list: PageList,
    create: PageCreate,
    edit: PageEdit,
    icon: ArticleIcon,
};

export default pages;