import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

const NavLayoutItem = ({ children, ...rest }) => {
    const StyledButton = styled(Button)(() => ({
        color: 'black',
        fontSize: '0.9em',
        fontWeight: 'bold',
        justifyContent: 'start',
        textDecoration: 'none',
        padding: '1.5em 0.75em',
        width: '100%'
    }));

    const StyledNavLink = styled(NavLink)(() => ({
        '&.active > button': {
            backgroundColor: '#EEE',
            color: 'crimson',
        }
    }));

    return (
        rest?.to ?
            <StyledNavLink {...rest}>
                <StyledButton>{children}</StyledButton>
            </StyledNavLink> :
            <StyledButton {...rest}>{children}</StyledButton>
    )
};

export default NavLayoutItem;