import { AutocompleteArrayInput, Create, ReferenceArrayInput, SelectInput, SimpleForm, TextInput, choices, regex, required, useNotify, useRecordContext, useRedirect } from 'react-admin';

const ClubCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const Title = () => {
        const record = useRecordContext();
        return <span>{ record ? record.name : 'New club' }</span>
    };

    const validationRules = {
        name: [required()],
        url: [required(), regex(/^https?:\/\/[^ ]+$/, 'Enter a URL starting with "https://" or "http://"')],
        status: [required(), choices(['pending', 'active', 'inactive'])]
    };
    
    const onSuccess = () => {
        notify('Club created');
        redirect('list', 'clubs');
    };

    return (
        <Create title={<Title />} mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm>
                <TextInput source="name" validate={validationRules.name} />
                <TextInput source="town" />
                <TextInput source="url" validate={validationRules.url} />
                <SelectInput source="status" choices={[
                    { id: "pending", name: "Pending" },
                    { id: "active", name: "Active" },
                    { id: "inactive", name: "Inactive" },
                ]} validate={validationRules.status} />
                <ReferenceArrayInput
                    source="manager_ids"
                    reference="users" 
                    sort={{ field: 'email' }}
                    enableGetChoices={({ q }) => ( q && q.length >= 1 )}
                >
                    <AutocompleteArrayInput
                        label="Managers"
                        source="manager_ids"
                        filterToQuery={(q) => ({ q: q })}
                        inputText={(record) => record.name ? `${record.email} (${record.name})` : record.email}
                        optionText={(record) => record.name ? `${record.email} (${record.name})` : record.email}
                        noOptionsText="Search by email address"
                        shouldRenderSuggestions={(val) => ( val.trim().length >= 1 ) }
                        fullWidth={true}
                    />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default ClubCreate;