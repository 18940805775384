import { Link } from '@mui/material';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Footer = () => {
    useTheme();

    return (
        <Stack spacing={4} direction="row" sx={{ alignItems: 'center', justifyContent: 'center', py: 1, px: 2 }}>
            <Typography>&copy; {(new Date()).getFullYear()} SledTRX</Typography>
            <Link color="primary" to="/Terms" displayPrint="none">Terms of Service & Privacy Policy</Link>
        </Stack>
    );
}

export default Footer;