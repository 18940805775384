import FirebaseRealtimeDatabaseProvider from "../../../providers/FirebaseRealtimeDatabaseProvider";
import { config } from "../../../firebase/firebase";

/**
 * Takes all trails and if they don't have a trail_type field set, set it to snowmobile
 * 
 * Trail type is a new field which will support switching between maps for different seasons (ATV, snowmobile, maybe more eventually)
 * 
 * Most likely should only run this once.
 * 
 * @returns {Promise<void>}
 */
const defaultAllTrailtsToSnowmobileType = async () => {
    // make sure the user is sure before continuing
    if (!window.confirm('This will set all trails without a trail_type to snowmobile. Are you sure you want to continue?')) {
        return;
    }

    const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL)

    const trails = await dataProvider.getList('trails', {
        sort: { field: 'name' },
    });

    // filter to those without a trail_type
    const trailsWithoutType = trails.data.filter(trail => !trail.trail_type);

    console.log(`Found ${trailsWithoutType.length} trails without a trail_type`)

    // Update each trail to have a trail_type of snowmobile
    trailsWithoutType.forEach((trail) => {
        if (trail.trail_type) {
            return
        }

        console.log(`Updating trail ${trail.id} to snowmobile`)

        dataProvider.update('trails', {
            id: trail.id,
            data: {
                ...trail,
                trail_type: 'snowmobile',
            },
        });
    });
}

export { defaultAllTrailtsToSnowmobileType };