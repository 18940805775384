import { useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';

export const StatusField = () => {
    const record = useRecordContext();
    if (!record || !record.status) {
        return null;
    }
    return (
        <Chip
            label={record.status.charAt(0).toUpperCase() + record.status.substr(1)}
            size="small"
            sx={{
                backgroundColor: record.status === 'active' ? 'success.main' : 'danger.main',
                color: '#FFFFFF',
            }}
            />
    )
};