import FirebaseRealtimeDatabaseProvider from "../../../providers/FirebaseRealtimeDatabaseProvider";
import { config } from "../../../firebase/firebase";

/**
 * Takes all trips and if they don't have a trip_type field set, set it to snowmobile
 * 
 * Trip type is a new field which will support switching between maps for different seasons (ATV, snowmobile, maybe more eventually)
 * 
 * Most likely should only run this once.
 * 
 * @returns {Promise<void>}
 */
const defaultAllTripsToSnowmobileType = async () => {
    // make sure the user is sure before continuing
    if (!window.confirm('This will set all trips without a trip_type to snowmobile. Are you sure you want to continue?')) {
        return;
    }

    const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL)

    const trips = await dataProvider.getList('trips');

    // filter to those without a trip_type
    const tripsWithoutType = trips.data.filter(trip => !trip.trip_type);

    console.log(`Found ${tripsWithoutType.length} trips without a trip_type`)

    // Update each trip to have a trip_type of snowmobile
    tripsWithoutType.forEach((trip) => {
        if (trip.trip_type) {
            return
        }

        console.log(`Updating trip ${trip.id} to snowmobile`)

        dataProvider.update('trips', {
            id: trip.id,
            data: {
                ...trip,
                trip_type: 'snowmobile',
            },
        });
    });
}

export { defaultAllTripsToSnowmobileType };