import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';

const NavLayout = ({ children, navigation, secondary }) => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setMenuOpen(false);
    }, [navigate]);

    return (
        <Stack sx={{ borderTop: '1px solid #ccc', boxSizing: 'border-box', flexDirection: { xs: 'column', sm: 'row' }, mx: 2, height: '90vh' }}>
            <Stack sx={{ borderRight: { xs: 'none', sm: '1px solid #ccc' }, borderBottom: { xs: '1px solid #ccc', sm: 'none' }, flexShrink: 0, my: { xs: 0, sm: 2 }, width: { xs: '100%', sm: '20%' } }}>
                <Button sx={{ borderBottom: menuOpen ? '1px solid #ddd' : 'none', py: 2, display: { xs: 'flex', sm: 'none' } }} onClick={() => setMenuOpen(!menuOpen)}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <MenuIcon />
                        <Box sx={{ fontSize: 'small', fontWeight: 'bold', textTransform: 'uppercase' }}>Menu</Box>
                    </Stack>
                </Button>
                <Stack sx={{ justifyContent: 'space-between', height: { xs: 'auto', sm: '100%' }, display: { xs: menuOpen ? 'flex' : 'none', sm: 'flex' } }}>
                    <>
                        { navigation }
                        { secondary }
                    </>
                </Stack>
            </Stack>
            <Box sx={{ flexGrow: 1, overflow: 'auto', ml: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 1 }, py: 2 }}>
                { children }
            </Box>
        </Stack>
    );
};

export default NavLayout;