import { DeleteButton, Edit, ImageField, ImageInput, SaveButton, SimpleForm, TextInput, Toolbar, regex, required, useNotify, useRedirect, useShowController } from 'react-admin';
import { AlignmentButtons, ClearButtons, FormatButtons, LevelSelect, LinkButtons, ListButtons, QuoteButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import * as DOMPurify from 'dompurify';

const PageEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const { isLoading, record } = useShowController();

    //Limit editing for system reserved pages
    const reservedPages = ['Home', 'LocalReport', 'Backcountry', 'Store', 'Privacy', 'Terms'];
    
    const validationRules = {
        name: [required()],
        slug: [regex(/[a-zA-Z0-9-]+$/, 'Slug can only include letters, numbers, and dashes')],
        video_url: [regex(/^https?:\/\/((www\.)?youtube\.com|youtu\.be)/, 'Enter a YouTube URL starting with "https://" or "http://"')],
    };
    
    const EditorToolbar = ({ size, ...props }) => {
        return (
            <RichTextInputToolbar {...props}>
                <LevelSelect size={size} />
                <FormatButtons size={size} />
                <AlignmentButtons size={size} />
                <ListButtons size={size} />
                <LinkButtons size={size} />
                <QuoteButtons size={size} />
                <ClearButtons size={size} />
            </RichTextInputToolbar>
        );
    };

    //Use a custom toolbar that only shows a delete button for non-reserved pages
    const FormToolbar = (props) => (
        <Toolbar {...props}>
            <SaveButton />
            {
                (!reservedPages.includes(record.slug) ?
                    <DeleteButton /> :
                    null
                )
            }
        </Toolbar>
    );

    const Title = () => {
        return <span>{ record ? record.title : 'New page' }</span>
    };
    
    const onSuccess = () => {
        notify('Page updated');
        redirect('list', 'pages');
    };

    if (isLoading) {
        return (
            <Stack alignItems="center" justifyContent="center" sx={{ py: 4 }}>
                <CircularProgress />
            </Stack>
        )
    }

    return (
        <Edit title={<Title />} mutationMode="pessimistic" mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm toolbar={<FormToolbar sx={{ justifyContent: 'space-between' }} />}>
                <TextInput source="title" validate={validationRules.title} />
                {
                    (!reservedPages.includes(record.slug) ?
                        <TextInput source="slug" validate={validationRules.slug} /> :
                        null
                    )
                }
                {
                    record?.slug === 'Home' ?
                        <>
                            <RichTextInput 
                                source="intro"
                                format={(value) => DOMPurify.sanitize(value)}
                                toolbar={<EditorToolbar />}
                                fullWidth={true} 
                            />
                            <TextInput source="video_url" label="YouTube Video URL" validate={validationRules.video_url} />
                        </> :
                        null
                }
                <RichTextInput 
                    source="content"
                    format={(value) => DOMPurify.sanitize(value)}
                    toolbar={<EditorToolbar />}
                    fullWidth={true} 
                />
                <ImageInput source="header_photo">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export default PageEdit;