import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCreate from './LocationCreate';
import LocationEdit from './LocationEdit';
import LocationList from './LocationList';

const locations = {
    list: LocationList,
    create: LocationCreate,
    edit: LocationEdit,
    icon: LocationOnIcon,
};

export default locations;