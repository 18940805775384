import './App.css';
import { forwardRef } from 'react';
import { BrowserRouter as Router, Link as RouterLink, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { AuthUserProvider } from './firebase/auth';
import { MapDataProvider } from './providers/MapDataProvider';

import Layout from './components/Layout/Layout';
import SledTRXAdmin from './routes/SledTRXAdmin/SledTRXAdmin';

import Account from './routes/Account/Account';
import AccountUpdate from './routes/AccountUpdate/AccountUpdate';
import ContentPage from './routes/ContentPage/ContentPage';
import Home from './routes/Home/Home';
import LocalHub from './routes/LocalHub/LocalHub';
import Map from './routes/Map/Map';
import PrintTrip from './routes/PrintTrip/PrintTrip';
import Profile from './routes/Profile/Profile';
import Trails from './routes/Trails/Trails';
import TermsOfService from './routes/TermsOfService/TermsOfService';
import ReactGA from 'react-ga4';

const App = () => {
    const state = {
        data: null
    };

    const linkBehavior = forwardRef((props, ref) => {
        const { href, to, ...rest } = props;
        return <RouterLink ref={ref} to={to ?? href} {...rest} />
    });

    // Google Analytics
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#e53935',
            }
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'capitalize',
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: linkBehavior,
                }
            },
            MuiLink: {
                defaultProps: {
                    component: linkBehavior,
                },
            }
        },
    });

    return (
        <AuthUserProvider>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path="/admin/*" element={<SledTRXAdmin />} />
                        <Route element={<Layout />}>
                            <Route path="/" element={<Home />} />
                            <Route element={<MapDataProvider />}>
                                <Route path="/Map" element={<Map />} />
                                <Route path="/PrintTrip/:id" element={<PrintTrip />} />
                            </Route>
                            <Route path="/LocalHub" element={<LocalHub />}>
                                <Route path="*" element={<ContentPage />} />
                            </Route>
                            <Route path="/Account" element={<Account />}>
                                <Route path="Profile" element={<Profile />} />
                                <Route path="Update" element={<AccountUpdate />} />
                                <Route path="Trails" element={<Trails />} />
                            </Route>
                            <Route path="/Terms" element={<TermsOfService />} />
                            <Route path="*" element={<div />} />
                        </Route>
                    </Routes>
                </Router>
            </ThemeProvider>
        </AuthUserProvider>
    );
}

export default App;