import './TripBuilderSideBar.css';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';

import { useAuth } from '../../firebase/auth';
import { useMapData } from '../../providers/MapDataProvider';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

import AccountButtons from '../AccountButtons/AccountButtons';
import SearchResults from '../SearchResults/SearchResults';
import TripComponentListItemContent from '../TripComponentListItemContent/TripComponentListItemContent';
import { FormControl, InputLabel, Select } from '@mui/material';

export default function TripBuilderSideBar({ setMapCoordinates, trip, setTrip, tripType, setTripType }) {
    const { authUser } = useAuth();

    const { locations, allTrails, mapDataLoading } = useMapData();

    //Array of the auth'd user's trips
    const [trips, setTrips] = useState([]);
    const [hasLoadedTrips, setHasLoadedTrips] = useState(false);

    //Stores the updated name when a trip is added
    const [newTripName, setNewTripName] = useState('');

    const [searchValue, setSearchValue] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);

    //Stores the element to which to attach the dropdown menu
    const [tripMenuAnchorElement, setTripMenuAnchorElement] = useState(null);
    const tripMenuOpen = Boolean(tripMenuAnchorElement);

    const [tripOpen, setTripOpen] = useState(false);
        
    const dataProvider = FirebaseRealtimeDatabaseProvider();

    //Load the user's trips
    useEffect(() => {
        //Only continue if there's an auth'd user and the map data has loaded
        if (!(authUser && authUser.id)) return;
        if (mapDataLoading) return;

        //Read in the user's trips
        dataProvider.getList('trips', {
            sort: { field: 'user' },
            filter: { user: authUser.id }
        })
            .then((result) => {
                // filter results by trip type
                if (tripType) {
                    result.data = result.data.filter(trip => trip.trip_type === tripType);
                }

                setTrips(result.data);

                //If there's only one trip when the page first loads, 
                //show it in the sidebar. This is only run on initialization 
                //so that the user can choose to see a list of their trips 
                //if they only have one trip.
                // if (!hasLoadedTrips && !trip && result.data.length === 1) {
                //     setTrip(result.data[0]);
                // }

                setHasLoadedTrips(true);
            });
    }, [authUser, mapDataLoading, trip, tripType]);

    //Close the trip menu if there are changes to the trip
    useEffect(() => {
        setTripMenuAnchorElement(null);
    }, [trip]);

    //Saves a new trip to the database
    const addTrip = () => {
        dataProvider.create('trips', {
            data: {
                components: {},
                duration: 0,
                mileage: 0,
                name: newTripName,
                user: authUser.id,
                trip_type: tripType
            }
        }).then((result) => {
            //Close the dialog, reset the new trip name, and update internal state
            setDialogOpen(false);
            setNewTripName('');
            setTrip(result.data);
            setTrips(t => [
                ...t,
                result.data
            ]);
        });
    };

    //Deletes a trip from the database and internal state
    const deleteTrip = () => {
        const tripId = trip.id;

        setTrips(trips.filter((tripData) => (tripId !== tripData.id)));
        setTrip(null);

        dataProvider.delete('trips', { id: tripId });
    };

    /**
     * Gets a readable string from a mileage value
     * 
     * @param {float} mileage Distance in miles
     * @return {string} Readable distance string with distance in tenths of miles
     */
    const getReadableMileage = (mileage) => {
        if (!mileage) return 'No Mileage Data';

        return +mileage?.toFixed(1) + ((mileage < 1 && mileage > 0) ? ' mile' : ' miles');
    };

    /**
     * Gets a readable duration from a number of minutes
     * 
     * @param {float} duration Number of minutes
     * @return {string} Readable duration string in hours or minutes
     */
    const getReadableDuration = (duration) => {
        if (duration >= 60) {
            const hours = Math.round(duration / 60);
            return hours + (hours === 1 ? ' hour' : ' hours');
        } else {
            return duration + (duration === 1 ? ' minute' : ' minutes');
        }
    };

    /**
     * Finds an approximate midpoint for a trail
     * 
     * @param {Object} trail Trail record
     * @return {Object} Coordinates with {lat, lng, zoom}
     */
    const getTrailMidpointCoordinates = (trail) => {
        let midpoint = Math.floor(trail['coordinates'].length / 2);
        return {
            lat: trail.coordinates[midpoint][1],
            lng: trail.coordinates[midpoint][0],
            zoom: 12
        };
    };

    /**
     * Fetches the record for a trip component
     * 
     * @param {string} type Type of component ('trail' or 'location')
     * @param {Object} id Component identifier
     * @return {Object} Data from the record
     */
    const getTripComponent = (type, id) => {
        switch (type) {
            case 'location':
                return locations?.find((location) => location.id === id);
            case 'trail':
            default:
                return allTrails?.find((trail) => trail.id === id);
        }
    };

    //Handles when a list item is clicked
    const handleListItemClick = (coordinates) => {
        setMapCoordinates(coordinates);
        setTripOpen(false);
    };

    //Saves a trip's new name to the database
    const renameTrip = () => {
        dataProvider.update('trips', {
            id: trip.id,
            data: {
                ...trip,
                name: newTripName
            }
        }).then((result) => {
            //Close the dialog, reset the new trip name, and update internal state
            setDialogOpen(false);
            setNewTripName('');
            setTrip(result.data);
        });
    };

    /**
     * Content to show for the trip type selector
     */
    const tripTypeSelectorContent = (
        <Stack spacing={1} direction="row" sx={{ borderBottom: '1px solid #CCC', alignItems: 'center', py: 2, pl: 2, pr: 1 }}>
            <MapOutlinedIcon sx={{ stroke: 'white', strokeWidth: 1 }} />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>Trip Type</Typography>
            <FormControl id="trip-type-selector">
                <Select
                    value={tripType}
                    onChange={(e) => setTripType(e.target.value)}
                >
                    <MenuItem value="snowmobile">Snowmobile</MenuItem>
                    <MenuItem value="atv">ATV</MenuItem>
                </Select>
            </FormControl>
        </Stack>
    );

    const addTripDialog = (
        <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                Add a New Trip
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Trip Name"
                    margin="dense"
                    fullWidth
                    value={newTripName}
                    variant="standard"
                    onChange={(e) => setNewTripName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>
                    Cancel
                </Button>
                <Button onClick={addTrip}>
                    Create Trip
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renameTripDialog = (
        <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle id="alert-dialog-title">
                Rename Trip
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Trip Name"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setNewTripName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>
                    Cancel
                </Button>
                <Button onClick={renameTrip}>
                    Rename Trip
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Content to show for a list of all of a user's trips
    const tripsListContent = (
        <>
            <Stack sx={{ height: 'auto' }}>
                {tripTypeSelectorContent}
                <Stack spacing={1} direction="row" sx={{ borderBottom: '1px solid #CCC', alignItems: 'center', py: 2, pl: 2, pr: 1 }}>
                    <MapOutlinedIcon sx={{ stroke: 'white', strokeWidth: 1 }} />
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>Your Trips</Typography>
                    <IconButton color="primary" onClick={() => setDialogOpen(true)}><AddIcon titleAccess="Add a new trip" /></IconButton>
                </Stack>
                <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                    {trips.map((tripData) => (
                        <ListItemButton key={tripData.id} onClick={() => setTrip(tripData)}>
                            <ListItemText primary={tripData.name} secondary={getReadableMileage(tripData.mileage) + ' - ' + getReadableDuration(tripData.duration)} />
                        </ListItemButton>
                    ))}
                </List>
            </Stack>
            {addTripDialog}
        </>
    );

    //Content to show if the user hasn't created any trips
    const introContent = (
        <>
            {tripsListContent}
            <Stack spacing={2} sx={{ justifyContent: 'center', mx: 1, height: '100%', pb: 2 }}>
                {!searchValue ? 
                    <Stack spacing={1} sx={{ alignItems: { xs: 'start', sm: 'center' }, flexGrow: { xs: 1, sm: 0 }, mx: { xs: 0, sm: 'auto' }, px: 1, width: { xs: '100%', sm: '90%' } }}>
                        <Stack sx={{ alignItems: 'center', flexDirection: { xs: 'row', sm: 'column' } }}>
                            <MapOutlinedIcon sx={{ fontSize: { xs: 20, sm: 48 }, mr: { xs: 1, sm: 0 } }} />
                            <Typography variant="h6" sx={{ textAlign: { xs: 'start', sm: 'center'}, mt: '0 !important' }}>Plan your next trip.</Typography>
                        </Stack>
                        <Typography variant="body1" sx={{ textAlign: { xs: 'start', sm: 'center'}, mx: { xs: 0, sm: 'auto' }, width: { xs: '100%', sm: '80%' } }}>Search for trails and points of interest to add to your next snowmobiling adventure.</Typography>
                    </Stack> : null
                }
                <Box sx={{ width: '100%', boxSizing: 'border-box', px: 1, pt: { xs: 0, sm: 2 } }}>
                    <Paper component="form" sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 1 }}>
                        <SearchIcon fontSize="small" sx={{ py: 1 }} />
                        <InputBase
                            value={searchValue}
                            placeholder="Find trails and points of interest..."
                            inputProps={{ 'aria-label': 'Search for locations and trails' }}
                            onChange={(e) => setSearchValue(e.target.value)}
                            sx={{ ml: 1, flex: 1 }}
                        />
                        {
                            searchValue ?
                                <IconButton onClick={() => setSearchValue('')}>
                                    <HighlightOffIcon fontSize="small" />
                                </IconButton> :
                                null
                        }
                    </Paper>
                </Box>
                {searchValue ?
                    <SearchResults q={searchValue} setMapCoordinates={setMapCoordinates} getTrailMidpointCoordinates={getTrailMidpointCoordinates} handleListItemClick={handleListItemClick} tripType={tripType} /> :
                    null
                }
            </Stack>
        </>
    );

    //Content to show while the map is loading
    const loadingContent = (
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress color="primary" size={32} />
        </Stack>
    );

    //Content to show when there's no logged in user
    const loggedOutContent = (
        <>
            {tripTypeSelectorContent}
            <Stack spacing={2} sx={{ alignItems: 'center', boxSizing: 'border-box', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: 'center', width: { xs: '100%', sm: '80%'}, height: '100%', mx: { xs: 0, sm: 'auto' }, px: { xs: 2, sm: 'auto' }, py: 2 }}>
                <Stack spacing={1} sx={{ flexGrow: { xs: 1, sm: 0 } }}>
                    <Stack sx={{ alignItems: 'center', flexDirection: { xs: 'row', sm: 'column' } }}>
                        <MapOutlinedIcon sx={{ fontSize: { xs: 20, sm: 48 }, mr: { xs: 1, sm: 0 } }} />
                        <Typography variant="h6" sx={{ textAlign: { xs: 'start', sm: 'center'}, mt: '0 !important' }}>Plan your next trip.</Typography>
                    </Stack>
                    <Typography variant="body1" sx={{ textAlign: { xs: 'start', sm: 'center'} }}>Create an account to plan your next trip.</Typography>
                </Stack>
                <AccountButtons />
            </Stack>
        </>
    );

    //Content to show for a specific trip
    const tripContent = (
        <>
            <Stack spacing={1} sx={{ height: { xs: tripOpen ? '100%' : 'auto', sm: '100%' }, pb: tripOpen ? 0 : 1 }}>
                <Stack spacing={1} direction="row" sx={{ alignItems: 'start', py: 1, pl: 1, pr: 1 }}>
                    <IconButton
                        id="expand-button"
                        onClick={() => setTripOpen(!tripOpen)}
                        sx={{ display: { xs: 'flex', sm: 'none' } }}
                    >
                        { tripOpen ? <ExpandLessIcon titleAccess="Hide trip details" /> : <ExpandMoreIcon titleAccess="Show trip details" /> }
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" sx={{ flexGrow: 1 }}>{trip?.name}</Typography>
                        { trip ? 
                            <Typography variant="body2">{getReadableMileage(trip.mileage)} - {getReadableDuration(trip.duration)}</Typography>
                            : null
                        }
                    </Box>
                    <IconButton
                        id="trip-menu-button"
                        aria-controls={tripMenuOpen ? 'trip-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={tripMenuOpen ? 'true' : undefined}
                        onClick={(event) => setTripMenuAnchorElement(event.currentTarget)}
                    >
                        <MoreVertIcon titleAccess="Show trip menu" />
                    </IconButton>
                    <Menu
                        id="trip-menu"
                        anchorEl={tripMenuAnchorElement}
                        open={tripMenuOpen}
                        onClose={() => setTripMenuAnchorElement(null)}
                        MenuListProps={{
                            'aria-labelledby': 'trip-menu-button',
                        }}
                    >
                        <MenuItem onClick={() => setTrip(null)}>Your trips</MenuItem>
                        <MenuItem onClick={() => setDialogOpen(true)}>Rename trip</MenuItem>
                        <MenuItem onClick={deleteTrip}>Delete trip</MenuItem>
                    </Menu>
                </Stack>
                <Stack sx={{ flexGrow: 1, overflow: 'auto', display: { xs: tripOpen ? 'flex' : 'none', sm: 'flex' } }}>
                    <Box sx={{ width: '100%', boxSizing: 'border-box', px: 1 }}>
                        <Paper component="form" sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 1 }}>
                            <SearchIcon fontSize="small" sx={{ py: 1 }} />
                            <InputBase
                                value={searchValue}
                                placeholder="Find trails and points of interest..."
                                inputProps={{ 'aria-label': 'Search for locations and trails' }}
                                onChange={(e) => setSearchValue(e.target.value)}
                                sx={{ ml: 1, flex: 1 }}
                            />
                            {
                                searchValue ?
                                    <IconButton onClick={() => setSearchValue('')}>
                                        <HighlightOffIcon fontSize="small" titleAccess="Clear search" />
                                    </IconButton> :
                                    null
                            }
                        </Paper>
                    </Box>
                    { searchValue ? 
                        <SearchResults q={searchValue} setMapCoordinates={setMapCoordinates} getTrailMidpointCoordinates={getTrailMidpointCoordinates} handleListItemClick={handleListItemClick} /> :
                        <>
                            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                                {(trip?.components) ?
                                    Object.entries(trip.components).map((component) => {
                                        const componentData = getTripComponent(component[1].type, component[1].id);

                                        if (!componentData) return null;

                                        return (
                                            component[1].type === 'trail' ?
                                                <ListItemButton key={component[0]} onClick={() => handleListItemClick(getTrailMidpointCoordinates(componentData))}>
                                                    <TripComponentListItemContent type='trail' component={componentData} />
                                                </ListItemButton> :
                                                <ListItemButton key={component[0]} onClick={() => handleListItemClick({ lat: componentData.latitude, lng: componentData.longitude, zoom: 12 })}>
                                                    <TripComponentListItemContent type='location' component={componentData} />
                                                </ListItemButton>
                                        )
                                    }) : null
                                }
                            </List>
                            <Stack direction="row" sx={{ justifyContent: 'end', p: 1, display: { xs: 'none', sm: 'flex' } }}>
                                <Button variant="contained" href={'/PrintTrip/'+trip?.id}>Print Ride Plan</Button>
                            </Stack>
                        </>
                    }
                </Stack>
            </Stack>
            {renameTripDialog}
        </>
    );

    return (
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.95)', borderRadius: 2, width: { xs: '100%', sm: '300px' }, top: 0, bottom: 0, right: 0, mt: { xs: 0, sm: 1 }, mr: { xs: 0, sm: 2 }, mb: { xs: 0, sm: 2 }, height: { xs: (!authUser || (authUser && !trips?.length) || (trip && !tripOpen)) ? 'min-content' : '100%', sm: 'auto' }, position: 'absolute', zIndex: 10, overflowY: "hidden" }}>
            {((!authUser && mapDataLoading) || (authUser && !hasLoadedTrips)) ?
                loadingContent :
                (authUser ?
                    ((trips && trips.length) ?
                        (trip ?
                            tripContent :
                            tripsListContent
                        ) : introContent
                    ) : loggedOutContent)
            }
        </Box>
    );
}