import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const TripComponentListItemContent = ({ type, component }) => {
    /**
     * Gets a readable string from a mileage value
     * 
     * @param {float} mileage Distance in miles
     * @return {string} Readable distance string with distance in tenths of miles
     */
    const getReadableMileage = (mileage) => {
        if (!mileage) return 'No Mileage Data';

        return +mileage?.toFixed(1) + ((mileage < 1 && mileage > 0) ? ' mile' : ' miles');
    };

    /**
     * Gets a readable duration from a number of minutes
     * 
     * @param {float} duration Number of minutes
     * @return {string} Readable duration string in hours or minutes
     */
    const getReadableDuration = (duration) => {
        if (duration >= 60) {
            const hours = Math.round(duration / 60);
            return hours + (hours === 1 ? ' hour' : ' hours');
        } else {
            return duration + (duration === 1 ? ' minute' : ' minutes');
        }
    };

    return (
        type === 'trail' ?
            <>
                <ListItemIcon>
                    <TrendingUpIcon fontSize="small" titleAccess="Trail" />
                </ListItemIcon>
                <ListItemText primary={component.name} secondary={getReadableMileage(component.mileage) + ' - ' + getReadableDuration(component.duration)} />
            </> :
            <>
                <ListItemIcon>
                    <LocationOnIcon fontSize="small" titleAccess="Location" />
                </ListItemIcon>
                <ListItemText primary={component.name} />
            </> 
    );
}

export default TripComponentListItemContent;