import FirebaseRealtimeDatabaseProvider from "../../../providers/FirebaseRealtimeDatabaseProvider";
import { config } from "../../../firebase/firebase";

/**
 * Delete all trails with ATV type
 * 
 * @returns {Promise<void>}
 */
const deleteAllATVTrails = async () => {
    // make sure the user is sure before continuing
    if (!window.confirm('This will delete all trails with a trail_type of ATV. Are you sure you want to continue?')) {
        return;
    }

    const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL)

    const trails = await dataProvider.getList('trails', {
        sort: { field: 'name' },
    });

    // filter to those with a trail_type of ATV
    const atvTrails = trails.data.filter(trail => trail.trail_type === 'atv');

    console.log(`Found ${atvTrails.length} trails with a trail_type of ATV`)

    // Delete each trail with a trail_type of ATV
    atvTrails.forEach((trail) => {
        console.log(`Deleting trail ${trail.id}`)
        dataProvider.delete('trails', trail.id);
    });
}

export { deleteAllATVTrails };