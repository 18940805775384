import { CreateButton, Datagrid, EditButton, FilterForm, ListBase, Pagination, TextField, TextInput, Title } from 'react-admin';
import Stack from '@mui/material/Stack';

const ClubList = () => {
    const filters = [<TextInput label="Filter" source="q" size="small" alwaysOn />];

    const ListToolbar = () => (
        <Stack direction="row">
            <FilterForm filters={filters} />
            <CreateButton />
        </Stack>
    );

    return (
        <ListBase sort={{ field: 'name', order: 'ASC' }} exporter={false}>
            <Title title="Locations" />
            <ListToolbar />
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" sortable={false} />
                <TextField source="town" sortable={false} />
                <EditButton />
            </Datagrid>
            <Pagination />
        </ListBase>
    );
};

export default ClubList;