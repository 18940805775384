import './Map.css';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import MapContainer from '../../components/MapContainer/MapContainer';
import TripBuilderSideBar from '../../components/TripBuilderSideBar/TripBuilderSideBar';

const Map = () => {
    //Set the default position for the map to the middle of Maine.
    const [latitude, setLatitude] = useState(44.8);
    const [longitude, setLongitude] = useState(-68.8);
    const [zoom, setZoom] = useState(6);

    // trip type (snowmobile, atv, etc.) to filter trails
    const [tripType, setTripType] = useState('snowmobile');

    //Holds the data for the currently active trip.
    const [trip, setTrip] = useState(null);

    const setCoordinates = (val) => {
        setLatitude(val.lat);
        setLongitude(val.lng);
        setZoom(val.zoom);
    }

    //Set the page title
    useEffect(() => {
        document.title = 'Explore the Map | SledTRX';
    }, []);

    return (
        <Box sx={{ boxSizing: 'border-box', height: '90vh', px: { xs: 0, sm: 1 }, pb: { xs: 0, sm: 1 }, position: 'relative' }}>
            <Box sx={{ borderRadius: 2, overflow: 'hidden' }} className="map">
                <MapContainer latitude={latitude} longitude={longitude} zoom={zoom} trip={trip} setTrip={setTrip} tripType={tripType} />
            </Box>
            <TripBuilderSideBar setMapCoordinates={setCoordinates} trip={trip} setTrip={setTrip} tripType={tripType} setTripType={setTripType} />
        </Box>
    );
};

export default Map;