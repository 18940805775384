/**
 * @license
 * Copyright 2022 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
 * ---
 * 
 * Updated to support user management via the database and to add the 
 * refreshAuthUser functionality.
 */

import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, signOut as authSignOut } from 'firebase/auth';
import FirebaseRealtimeDatabaseProvider from '../providers/FirebaseRealtimeDatabaseProvider';
import { auth } from './firebase';

export default function useFirebaseAuth() {
    const [authUser, setAuthUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const clear = () => {
        setAuthUser(null);
        setIsLoading(false);
    };

    const authStateChanged = async (user) => {
        setIsLoading(true);
        if (!user) {
            clear();
            return;
        }
        
        setAuthUser({
            uid: user.uid,
            email: user.email
        });

        const dataProvider = FirebaseRealtimeDatabaseProvider();

        dataProvider.getOne('users', { id: user.uid })
            .then((result) => {
                if (result.data?.uid) {
                    const authUser = result.data;

                    setAuthUser(authUser);
                    setIsLoading(false);
                } else {
                    const userDataToSave = {
                        email: user.email,
                        id: user.uid,
                        name: user.displayName,
                        type: 'default',
                        uid: user.uid
                    };
                
                    dataProvider.create('users', { data: userDataToSave })
                        .then((result) => {
                            setAuthUser(result.data);
                            setIsLoading(false);
                        });
                }
            })
    };

    const signOut = () => authSignOut(auth).then(clear);

    const refreshAuthUser = async () => authStateChanged(authUser);

    // Listen for Firebase Auth state change
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, authStateChanged);
        return () => unsubscribe();
    }, []);

    return {
        authUser,
        isLoading,
        signOut,
        refreshAuthUser
    };
}

const AuthUserContext = createContext({
    authUser: null,
    isLoading: true,
    signOut: async () => { },
    refreshAuthUser: async () => { }
});

export function AuthUserProvider({ children }) {
    const auth = useFirebaseAuth();
    return <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>;
}

export const useAuth = () => useContext(AuthUserContext);