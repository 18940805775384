import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAuth } from '../../firebase/auth';
import { useMapData } from '../../providers/MapDataProvider';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

import MapContainer from '../../components/MapContainer/MapContainer';
import TripComponentListItemContent from '../../components/TripComponentListItemContent/TripComponentListItemContent';

const PrintTrip = () => {
    const { authUser } = useAuth();

    const { locations, allTrails, mapDataLoading } = useMapData();

    const dataProvider = FirebaseRealtimeDatabaseProvider();

    //Grabs the ID from the page URL via routing.
    const { id } = useParams();

    //Holds the data for the currently active trip.
    const [trip, setTrip] = useState(null);

    /**
     * Fetches the record for a trip component
     * 
     * @param {string} type Type of component ('trail' or 'location')
     * @param {Object} id Component identifier
     * @return {Object} Data from the record
     */
    const getTripComponent = (type, id) => {
        switch (type) {
            case 'location':
                return locations.find((location) => location.id === id);
            case 'trail':
            default:
                return allTrails.find((trail) => trail.id === id);
        }
    };

    /**
     * Gets a readable string from a mileage value
     * 
     * @param {float} mileage Distance in miles
     * @return {string} Readable distance string with distance in tenths of miles
     */
    const getReadableMileage = (mileage) => {
        if (!mileage) return 'No Mileage Data';

        return +mileage?.toFixed(1) + ((mileage < 1 && mileage > 0) ? ' mile' : ' miles');
    };

    /**
     * Gets a readable duration from a number of minutes
     * 
     * @param {float} duration Number of minutes
     * @return {string} Readable duration string in hours or minutes
     */
    const getReadableDuration = (duration) => {
        if (duration >= 60) {
            const hours = Math.round(duration / 60);
            return hours + (hours === 1 ? ' hour' : ' hours');
        } else {
            return duration + (duration === 1 ? ' minute' : ' minutes');
        }
    };
    
    //Fetch the requested trip
    useEffect(() => {
        //Only continue if there's a user and map data
        if (!authUser || mapDataLoading) return;

        //Fetch the auth'd users trips
        dataProvider.getList('trips', {
            sort: { field: 'user' },
            filter: { user: authUser.id }
        })
            .then((result) => {
                //Set the trip based on the provided id
                const trip = result.data.find((trip) => (trip.id === id));
                setTrip(trip);

                //Set the page title
                document.title = `${trip.name} | SledTRX`;
            });
    }, [authUser, mapDataLoading]);

    return (
        <Stack spacing={2} direction="row" maxWidth="md" sx={{ width: '100%', mx: 'auto' }}>
            <Stack spacing={2} sx={{ width: '40%'}}>
                {trip ? 
                    <Box sx={{ width: '100%', height: 500, borderRadius: 2, overflow: 'hidden' }}>
                        <MapContainer trip={trip} mapType='display' tripType={trip.trip_type} />
                    </Box>
                : null}
                <Box displayPrint="none">
                    <Button variant="contained" fullWidth={true} onClick={() => window.print()}>Print Trip</Button>
                </Box>
            </Stack>
            <Box>
                <Typography variant="h1" sx={{ fontSize: 36, fontWeight: 'bold' }}>{trip?.name}</Typography>
                <Typography variant="body1" sx={{ mt: 1 }}><strong>Distance:</strong> {trip?.id ? getReadableMileage(trip.mileage) : '...' }</Typography>
                <Typography variant="body1"><strong>Estimated Travel Time:</strong> {trip?.id ? getReadableDuration(trip.duration) : '...' }</Typography>
                <List>
                    {trip?.components ? 
                        Object.entries(trip.components).map((component, index) => {
                            const componentData = getTripComponent(component[1].type, component[1].id);

                            return (
                                <ListItem key={index}>
                                    <TripComponentListItemContent type={component[1].type} component={componentData} />
                                </ListItem>
                            );
                        }) : null
                    }
                </List>
            </Box>
        </Stack>
    );
};

export default PrintTrip;