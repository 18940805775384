import { Stack } from '@mui/material';

const NavLayoutSection = ({ children }) => {
    return (
        <Stack>
            { children }
        </Stack>
    );
};

export default NavLayoutSection;