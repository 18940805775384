import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useMapData } from '../../providers/MapDataProvider';

import TripComponentListItemContent from '../TripComponentListItemContent/TripComponentListItemContent';

const SearchResults = ({ q, getTrailMidpointCoordinates, handleListItemClick, tripType }) => {
    const { locations, allTrails } = useMapData();

    //Store the results that match the query. When loaded, 
    //this will have 'locations' and 'trails' properties 
    //with arrays in each.
    const [results, setResults] = useState({});

    //Track which results tab is currently active
    const [tab, setTab] = useState('trails');

    //Filter the data based on the current query state
    useEffect(() => {
        let _tripType = tripType;
        if (!_tripType) {
            _tripType = 'snowmobile';
        }

        setResults(r => ({
            locations: locations.filter((location) => (location.name.toLowerCase().indexOf(q.toLowerCase()) !== -1)),
            trails: allTrails.filter((trail) => (trail.name.toLowerCase().indexOf(q.toLowerCase()) !== -1 && trail.trail_type === _tripType)),
        }));
    }, [q]);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} centered>
                    <Tab label="Trails" value="trails" onClick={() => setTab('trails')} />
                    <Tab label="Locations" value="locations" onClick={() => setTab('locations')} />
                </Tabs>
            </Box>
            { 
                tab === 'trails' ?
                    (
                        (results?.trails && results.trails.length) ?
                            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                                {results.trails.map((trail, index) => (
                                    <ListItemButton key={'trail-'+index} onClick={() => handleListItemClick(getTrailMidpointCoordinates(trail))}>
                                        <TripComponentListItemContent type='trail' component={trail} />
                                    </ListItemButton>
                                ))}
                            </List> :
                            <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="body2">No trails match your search.</Typography>
                            </Stack>
                    ) : (
                        (results?.locations && results.locations.length) ?
                            <List sx={{ flexGrow: 1, overflow: 'auto' }}>
                                {results.locations.map((location, index) => (
                                    <ListItemButton key={'location-'+index} onClick={() => handleListItemClick({lng: location.longitude, lat: location.latitude, zoom: 12})}>
                                        <TripComponentListItemContent type='location' component={location} />
                                    </ListItemButton>
                                ))}
                            </List> :
                            <Stack sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant="body2">No locations match your search.</Typography>
                            </Stack>
                    )
            }
        </>
    )
};

export default SearchResults;