import { Layout, Menu } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { AdminAppBar } from '../AdminAppBar/AdminAppBar';

const AdminMenu = (props) => (
    <Menu>
        <Menu.ResourceItem name="trails" />
        <Menu.ResourceItem name="locations" />
        <Menu.ResourceItem name="clubs" />
        <Menu.ResourceItem name="pages" />
        <hr />
        <Menu.Item to="/admin/import" primaryText="Import" leftIcon={<FileUploadIcon />} />
        <Menu.Item to="/admin/migrations" primaryText="Migrations" />
    </Menu>
);

export const AdminLayout = (props) => (window.location.hostname === 'localhost') ? (
    <>
        <Layout {...props} appBar={AdminAppBar} menu={AdminMenu} sx={{ '& .RaLayout-appFrame': { marginTop: 12 } }} />
        <ReactQueryDevtools />
    </>
) : (
    <Layout {...props} appBar={AdminAppBar} menu={AdminMenu} sx={{ '& .RaLayout-appFrame': { marginTop: 12 } }} />
);