import RouteIcon from '@mui/icons-material/Route';
import TrailEdit from './TrailEdit';
import TrailList from './TrailList';

const trails = {
    list: TrailList,
    edit: TrailEdit,
    icon: RouteIcon,
};

export default trails;