import './Popup.css';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const TripButton = ({ tripComponentType, tripComponentId, isInTrip, setIsInTrip, addTripComponent, removeTripComponent }) => {
    return (
        <Button
            variant="contained"
            sx={{ textTransform: 'capitalize', mt: 1, maxWidth: 200 }}
            className={isInTrip ? 'in_trip' : 'not_in_trip'}
            onClick={() => {
                if (isInTrip) {
                    removeTripComponent(tripComponentType, tripComponentId);
                } else {
                    addTripComponent(tripComponentType, tripComponentId);
                }
                setIsInTrip(!isInTrip);
            }}
        >
            {isInTrip ? 'Remove from Trip' : 'Add to Trip'}
        </Button>
    )
};

const ExtraInfoBox = ({ phone, address, website }) => {
    return (
        <Box className="location-popup-contact-info">
            {phone ? (
                <Box>
                    <Typography variant="caption">
                        <strong>Phone:</strong> {phone}
                    </Typography>
                </Box>
            ) : null}
            {address ? (
                <Box>
                    <Typography variant="caption">
                        <strong>Address:</strong> {address}
                    </Typography>
                </Box>
            ) : null}
            {website ? (
                <Box>
                    <Typography variant="caption">
                        <strong>Website:</strong>{' '}
                        <Link href={website} target="_blank" rel="noopener">
                            {website}
                        </Link>
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

const Popup = ({ tripComponentType, tripComponent, club, authUser, trip, addTripComponent, removeTripComponent, width }) => {
    //This is managed internally since the popup renders outside the scope
    //of the component.
    const [isInTrip, setIsInTrip] = useState(trip && trip.components && trip.components[tripComponentType + '-' + tripComponent.id]);
    const [titleSize, setTitleSize] = useState(tripComponentType === 'location' ? '22px' : '18px');
    const [validClubURL, setValidClubURL] = useState(false);
    const [validClubName, setValidClubName] = useState(true);

    /**
     * Gets a readable string from a mileage value
     * 
     * @param {float} mileage Distance in miles
     * @return {string} Readable distance string with distance in tenths of miles
     */
    const getReadableMileage = (mileage) => {
        if (!mileage) return 'No Mileage Data';

        return +mileage?.toFixed(1) + ((mileage < 1 && mileage > 0) ? ' mile' : ' miles');
    };
    /**
     * Gets a readable duration from a number of minutes
     * 
     * @param {float} duration Number of minutes
     * @return {string} Readable duration string in hours or minutes
     */
    const getReadableDuration = (duration) => {
        if (duration >= 60) {
            const hours = Math.round(duration / 60);
            return hours + (hours === 1 ? ' hour' : ' hours');
        } else {
            return duration + (duration === 1 ? ' minute' : ' minutes');
        }
    };

    /**
     * Use Effect to check if the club URL is valid
     * 
     * @return {void}
     */
    useEffect(() => {
        if (club && club.url) {
            // check if https or http is present
            if (club.url.indexOf('http://') === 0 || club.url.indexOf('https://') === 0) {
                setValidClubURL(true);
            } else {
                setValidClubURL(false);
            }
        }
        if (club && club.name) {
            if (club.name == '0') {
                setValidClubName(false);
            }
        }
    }, [club]);

    return (
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
            <Box>
                <Typography variant="h6" sx={{ fontSize: titleSize, lineHeight: 1.2, mr: 6, fontWeight: 'bold' }}>{tripComponent.name}</Typography>
                {
                    (tripComponentType === 'location') ?
                        <ExtraInfoBox phone={tripComponent.phone} address={tripComponent.address} website={tripComponent.website} /> : null
                }
                {
                    (tripComponent.description) ?
                        <Typography variant="body2">{tripComponent.description}</Typography> : null
                }
            </Box>
            {
                (tripComponentType === 'trail' && tripComponent.mileage && tripComponent.duration) ?
                    <Box>
                        <Typography variant="body2"><strong>Distance:</strong> {getReadableMileage(tripComponent.mileage)}</Typography>
                        <Typography variant="body2"><strong>Duration:</strong> {getReadableDuration(tripComponent.duration)}</Typography>
                    </Box> : null
            }
            { (club && validClubName) ?
                    <Stack>
                        <Typography variant="body2" sx={{ textAlign: 'center' }}>'Managed by</Typography>
                        { (club.url && validClubURL) ?
                            <Link variant="body1" color="primary" href={club.url} target="_blank" sx={{ textAlign: 'center' }} className="popup-club-link">{club.name}</Link> : 
                            <Typography variant="body1" sx={{ textAlign: 'center' }}>{club.name}</Typography>
                        }
                    </Stack> : null
            }
            { authUser ? (
                trip ?
                    <TripButton tripComponentType={tripComponentType} tripComponentId={tripComponent.id} isInTrip={isInTrip} setIsInTrip={setIsInTrip} addTripComponent={addTripComponent} removeTripComponent={removeTripComponent}></TripButton>
                    : 
                        <Button 
                            variant="contained"
                            sx={{ textTransform: 'capitalize', mt: 1, maxWidth: 225 }}
                            className={'not_in_trip'}
                            disabled
                        >
                            Select Trip in Sidebar First
                        </Button>
            ) : null
            }
        </Stack>
    )
};

export default Popup;