import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';

import { useAuth } from '../../firebase/auth';

const Profile = () => {
    const { authUser } = useAuth();

    //Set the page title
    useEffect(() => {
        document.title = 'Your Profile | SledTRX';
    }, []);

    return (
        <Stack spacing={4}>
            <Stack spacing={2} direction="row" sx={{ alignItems: 'center' }}>
                <Typography variant="h1" sx={{ fontSize: 36, fontWeight: 'bold', flexGrow: 1 }}>Your SledTRX Profile</Typography>
                <Button href="/Account/Update" startIcon={<EditIcon />}>Update</Button>
            </Stack>
            <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Your Name</Typography>
                <Typography variant="body1">{authUser?.name ?? '–'}</Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Your Town</Typography>
                <Typography variant="body1">{authUser?.town ?? '–'}</Typography>
            </Box>
        </Stack>
    );
};

export default Profile;