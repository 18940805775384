import { Edit, SelectInput, SimpleForm, TextInput, regex, required, useNotify, useRecordContext, useRedirect } from 'react-admin';
import MapPointInput from '../../components/MapPointInput/MapPointInput';

const LocationEdit = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const Title = () => {
        const record = useRecordContext();
        return <span>{ record ? record.name : 'New location' }</span>
    };
    
    const validationRules = {
        name: [required()],
        phone: [regex(/^(([0-9]{3}-|\([0-9]{3}\) )[0-9]{3}-[0-9]{4})$/, 'Invalid phone number (use XXX-XXX-XXXX or (XXX) XXX-XXXX)')],
        map: [required()],
        website: [regex(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/, 'Invalid website URL (use http:// or https://)')],
        trip_type: [required()],
    };
    
    const onSuccess = () => {
        notify('Location updated');
        redirect('list', 'locations');
    };
    
    return (
        <Edit title={<Title />} mutationMode="pessimistic" mutationOptions={{ onSuccess }} {...props}>
            <SimpleForm>
                <TextInput source="name" validate={validationRules.name} />
                <TextInput source="description" multiline={true} />
                <TextInput source="address" />
                <TextInput source="phone" validate={validationRules.phone} />
                <TextInput source="website" />
                <SelectInput source="trip_type" choices={[
                    { id: "snowmobile", name: "Snowmobile" },
                    { id: "atv", name: "ATV" },
                ]} validate={validationRules.trip_type} />
                <MapPointInput label="Location" latitude="latitude" longitude="longitude" validate={validationRules.map} />
            </SimpleForm>
        </Edit>
    );
};

export default LocationEdit;