import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';
import { useAuth } from '../../firebase/auth';
import { FormControl, Typography } from '@mui/material';
import firebase from 'firebase/compat/app';
import AppleSignInButton from '../../images/apple-signin-button.png'
import './AccountButtons.css';

const signinWithApple = () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    
    const auth = getAuth();

    signInWithPopup(auth, provider)
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.error(error);
        });
    
    getRedirectResult(auth)
        .then((result) => {
            console.log(result.user);
        }).catch((error) => {
            console.error(error);
        });
}

const AccountButtons = ({ includeLogin }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const { authUser, signOut, isLoading, refreshAuthUser } = useAuth();

    //Current dialog being shown ('login', 'initialization', or null for none)
    const [currentDialog, setCurrentDialog] = useState(null);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [accountInformation, setAccountInformation] = useState({});
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
    
    const dataProvider = FirebaseRealtimeDatabaseProvider();

    //For implementation details, see https://firebase.google.com/docs/auth/web/firebaseui
    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            GoogleAuthProvider.PROVIDER_ID,
        ]
    };

    //When there's a change to the authenticated user state, set the dialog 
    //state based on whether they've initialized their account
    useEffect(() => {
        if (!authUser || isLoading) return;

        setCurrentDialog(authUser.agreed_to_terms_at ? null : 'initialization');
    }, [authUser, isLoading]);

    const loadFirebaseUI = () => {
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(getAuth());
        ui.start('#firebaseui-auth-container', uiConfig);
    };

    //Sign out and close the dialog if they cancel initialization
    const handleCancel = () => {
        setCurrentDialog(null);
        signOut();
    };

    //Load the Firebase login UI when opening the sign in dialog
    const handleLoginDialogOpen = () => {
        setCurrentDialog('login');

        setTimeout(() => loadFirebaseUI(), 200);
    };

    //Save the updates to the database
    const saveUser = () => {
        if (!hasAgreedToTerms) {
            setErrorDialogOpen(true);
            return false;
        }

        const updatedInformation = {
            ...authUser,
            name: accountInformation.name ?? '',
            town: accountInformation.town ?? '',
            agreed_to_terms_at: (new Date()).toISOString()
        };
        dataProvider.update('users', { id: authUser.id, data: updatedInformation })
            .then((result) => {
                refreshAuthUser().then(() => {
                    if (location.pathname !== '/Map') {
                        navigate('/Map');
                    }
                });
                setCurrentDialog(null);
            });
    };

    //Dialog to sign in via Google
    const signInDialog = (
        <Dialog
            open={currentDialog === 'login'}
            onClose={() => setCurrentDialog(null)}
            aria-labelledby="login-dialog-title"
            style={{ textAlign: 'center' }}
        >
            <DialogTitle id="login-dialog-title">
                Sign into SledTRX
            </DialogTitle>
            <DialogContent>
                <div id="firebaseui-auth-container"></div>
                <img src={AppleSignInButton} alt="Sign in with Apple" onClick={signinWithApple} id="signin-with-apple" />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCancel()} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

    //Dialog to set up an account for the first time
    const accountInitializationDialog = (
        <Dialog
            open={currentDialog === 'initialization'}
            onClose={() => handleCancel()}
            aria-labelledby="initialization-dialog-title"
        >
            <DialogTitle id="initialization-dialog-title" sx={{ fontSize: 24, textAlign: 'center' }}>
                Welcome to SledTRX!
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ py: 1 }}>
                    <Typography sx={{ textAlign: 'center' }}>Set up your account to start creating and managing trips.</Typography>
                    <TextField
                        label="Your Name"
                        value={accountInformation?.name ?? ''}
                        onChange={(e) => setAccountInformation(ai => ({
                            ...ai,
                            name: e.target.value
                        }))}
                    />
                    <TextField
                        label="Your Town"
                        value={accountInformation?.town ?? ''}
                        onChange={(e) => setAccountInformation(ai => ({
                            ...ai,
                            town: e.target.value
                        }))}
                    />
                    <FormControl>
                        <FormControlLabel 
                            control={<Checkbox value="1" />} 
                            label={
                                <>
                                    <span>I agree to the </span>
                                    <Link to="/Terms" target="_blank">Terms Of Service & Privacy Policy</Link>
                                </>
                            }
                            required
                            onChange={(e) => setHasAgreedToTerms(e.target.value)}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCancel()}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => saveUser()} autoFocus>
                    Create Your Account
                </Button>
            </DialogActions>
        </Dialog>
    );

    //Dialog when the user hasn't checked the terms of service box
    const errorDialog = (
        <Dialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
            aria-labellledby="error-dialog-title"
        >
            <DialogTitle id="error-dialog-title" sx={{ textAlign: 'center' }}>
                Please agree to the terms of service.
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ textAlign: 'center' }}>You must agree to the terms of service to create your account.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={() => setErrorDialogOpen(false)} autoFocus>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {
                includeLogin ?
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" onClick={() => handleLoginDialogOpen()}>Login</Button>
                        <Button variant="contained" onClick={() => handleLoginDialogOpen()} sx={{ whiteSpace: 'nowrap', display: { xs: 'none', sm: 'flex' } }}>Sign up</Button>
                    </Stack> :
                    <Button variant="contained" onClick={() => handleLoginDialogOpen()} sx={{ whiteSpace: 'nowrap' }}>Sign up</Button>
            }
            {signInDialog}
            {accountInitializationDialog}
            {errorDialog}
        </>
    );
};

export default AccountButtons;