import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { AppBar, TitlePortal } from 'react-admin';
import Logo from '../../images/Header/SledTRXLogo.png';

export const AdminAppBar = (props) => {
    return (
        <AppBar color="secondary">
            <Box sx={{ py: '1rem', pr: '1rem' }}>
                <NavLink to="/admin"><img id="LogoImage" src={Logo} alt="SledTRX Logo"></img></NavLink>
            </Box>
            <TitlePortal />
            <Box flex="1" />
        </AppBar>
    );
};