import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import FirebaseRealtimeDatabaseProvider from '../providers/FirebaseRealtimeDatabaseProvider';

const useFirebaseMapData = () => {
    const [clubs, setClubs] = useState([]);

    //Array of locations, each of which has the following properties:
    //  - name
    //  - coordinates (Object with lat, lng, zoom)
    const [locations, setLocations] = useState([]);

    //Array of trails, each of which has the following properties:
    //  - name
    //  - distance
    //  - duration
    //  - coordinates (Object with lat, lng, zoom)
    //  - type ('trail')
    const [allTrails, setAllTrails] = useState([]);

    const [mapDataLoading, setMapDataLoading] = useState(true);

    const dataProvider = FirebaseRealtimeDatabaseProvider();

    //Read in information from database
    useEffect(() => {
        const clubsRetriever = dataProvider.getList('clubs');
        const locationsRetriever = dataProvider.getList('locations');
        const trailsRetriever = dataProvider.getList('trails');

        Promise.all([clubsRetriever, locationsRetriever, trailsRetriever])
            .then((results) => {
                // Process clubs
                setClubs(results[0].data);
                
                // Process locations
                setLocations(results[1].data);
                
                // Process trails
                setAllTrails(results[2].data);
            })
            .finally(() => setMapDataLoading(false));
    }, []);

    return {
        clubs,
        locations,
        allTrails,
        mapDataLoading
    };
}

const MapDataContext = createContext({
    clubs: [],
    locations: [],
    allTrails: [],
    mapDataLoading: false,
});

export const MapDataProvider = () => {
    const mapData = useFirebaseMapData();
    return <MapDataContext.Provider value={mapData}><Outlet /></MapDataContext.Provider>;
};

export const useMapData = () => useContext(MapDataContext);