import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import { useAuth } from '../../firebase/auth';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const Layout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { authUser, isLoading } = useAuth();

    useEffect(() => {
        if (!authUser || isLoading) return;

        //Go home if they haven't agreed to the terms
        if (!authUser.agreed_to_terms_at && !['/', '/Terms', '/Privacy'].includes(location.pathname)) {
            navigate('/');
        }
    }, [location]);

    return (
        <>
            <Stack sx={{ minHeight: '100vh' }}>
                <Header />
                <Stack sx={{ flexGrow: 1 }}>
                    <Outlet />
                </Stack>
                { location.pathname === '/' ? 
                    <Footer /> : null
                }
            </Stack>
        </>
    );
}

export default Layout;