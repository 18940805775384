import { useEffect, useState } from 'react';
import { FileField, FileInput, Form, SelectArrayInput, SelectInput, Title, useAuthenticated } from 'react-admin';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { config } from '../../firebase/firebase';
import firebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

const Import = () => {
    //Require login for this page
    useAuthenticated();

    //Remaining entities to import from the file
    const [entitiesToImport, setEntitiesToImport] = useState([]);

    //A running list of entities from the file used to build the table on the page
    const [importedEntities, setImportedEntities] = useState([]);

    //Cached data from the database used to check for existing entities
    const [clubs, setClubs] = useState([]);
    const [locations, setLocations] = useState([]);
    const [trails, setTrails] = useState([]);
    
    // other form data
    const [tripType, setTripType] = useState(null);
    
    //Flag to track whether the entities aboved have already been fetched
    const [hasLoadedData, setHasLoadedData] = useState(false);

    const dataProvider = firebaseRealtimeDatabaseProvider(config.databaseURL);

    //Check to see if an entity in the import file already exists in the database
    const checkIfExists = (entity) => {
        if (entity.geometry.type === 'Point') {
            //For locations, "exists" means that there's a point with the same latitude and longitude
            return Boolean(locations.filter((location) => (location.longitude === entity.geometry.coordinates[0] && location.latitude === entity.geometry.coordinates[1])).length);
        } else {
            //For trails, "exists" means that the coordinates of the trail are the same as provided in the file
            return Boolean(trails.filter((trail) => (JSON.stringify(trail.coordinates) === JSON.stringify(entity.geometry.coordinates[0]))).length);
        }
    };

    //Lookup to see if the associated club already exists; if it doesn't, create it
    const findOrCreateClubFromName = async (name, params) => {
        const matchingClub = clubs.find((club) => (club.name === name));

        if (matchingClub) {
            return matchingClub;
        } else {
            // create a new one and add it to the clubs list (reduces db queries)
            const result = await dataProvider.create('clubs', { data: params })
            setClubs(c => [...c, result.data]);

            // build new club object to return
            const newClub = {
                id: result.data.id,
                ...params,
            }

            return newClub;
        }
    };

    //Cache the existing lists of entities for comparison while processing the file
    const loadExistingData = () => {
        const clubFetcher = dataProvider.getList('clubs');
        const locationFetcher = dataProvider.getList('locations');
        const trailFetcher = dataProvider.getList('trails');

        Promise.all([clubFetcher, locationFetcher, trailFetcher]).then((result) => {
            setClubs(result[0].data);
            setLocations(result[1].data);
            setTrails(result[2].data);

            setHasLoadedData(true);
        });
    };

    //Do the operations necessary for an individual record ("entity") in the file
    const processEntity = async (entity) => {
        if (entity.geometry.type == 'LineString') {
            entity.geometry.type = 'MultiLineString';
            entity.geometry.coordinates = [entity.geometry.coordinates];
        }

        const entityExists = checkIfExists(entity);

        if (!entityExists) {
            await saveEntity(entity);
        }

        let description = null;

        // if entity.properties.description is a string, convert to object
        if (typeof entity.properties?.description === 'string') {
            description = JSON.parse(entity.properties.description);
        } else if (entity.properties?.description) {
            description = entity.properties.description;
        }

        //Update the internal tracking with the status of the entity
        if (entity.geometry.type === 'Point') {
            setImportedEntities(ie => [
                ...ie,
                {
                    'type': 'location',
                    'name': entity.properties.Name,
                    'description': entity.properties.Address,
                    'status': entityExists ? 'duplicate' : 'imported',
                    'trip_type': tripType,
                }
            ]);
        } else {
            setImportedEntities(ie => [
                ...ie,
                {
                    'type': 'trail',
                    'name': entity.properties.Name,
                    'description': entity.properties.Mileage ? entity.properties.Mileage + (entity.properties.Mileage <= 1 ? ' mile' : ' miles') : 
                        (description ? description.Mileage + (description.Mileage <= 1 ? ' mile' : ' miles') : ''),
                    'status': entityExists ? 'duplicate' : 'imported',
                    'trail_type': tripType,
                }
            ]);
        }

        setEntitiesToImport(eti => eti.filter((entityToImport) => (JSON.stringify(entity) !== JSON.stringify(entityToImport))));
    };

    //Read the selected file and kick off processing
    const readFile = (file) => {
        if (!tripType) {
            alert('Please select a type before importing');
            return
        }

        const fileReader = new FileReader();
        
        fileReader.addEventListener('load', (event) => {
            // cleaned string
            const cleanedString = cleanJsonString(fileReader.result)

            const fileContents = JSON.parse(cleanedString);

            setEntitiesToImport(fileContents.features);
        });
        
        fileReader.readAsText(file);
    };

    //Save a new entity to the database if it doesn't already exist
    const saveEntity = async (entity) => {
        let description = null;

        // if entity.properties.description is a string, convert to object
        if (typeof entity.properties?.description === 'string') {
            description = JSON.parse(entity.properties.description);
        } else if (entity.properties?.description) {
            description = entity.properties.description;
        }

        const club = entity.properties.Club ?
            await findOrCreateClubFromName(entity.properties.Club, {
                name: entity.properties.Club,
                url: entity.properties.ClubURL,
            }) : (
                description?.ClubUrl ?
                    await findOrCreateClubFromName(description.ClubUrl, {
                        name: description.ClubName,
                        url: description.ClubUrl,
                    }) : null
            );

        if (!club) {
            console.log('No club found for ' + entity.properties.Club);
        }

        if (entity.geometry.type === 'Point') {
            const newLocation = dataProvider.create('locations', { data: {
                address: entity.properties.Address,
                club_id: club ? club.id : '',
                description: entity.properties.Description,
                latitude: entity.geometry.coordinates[1],
                longitude: entity.geometry.coordinates[0],
                name: entity.properties.Name,
                phone: entity.properties.Phone,
                trip_type: tripType,
            }});

            setLocations(l => [
                ...l,
                newLocation
            ]);
        } else {
            // if entity has geometry with type LineString, update to have type MultiLineString and add extra [] around coordinates
            // if (entity.geometry.type == 'LineString') {
            //     entity.geometry.type = 'MultiLineString';
            //     entity.geometry.coordinates = [entity.geometry.coordinates];
            // }
            
            const newTrail = dataProvider.create('trails', { data: {
                club_id: club ? club.id : '',
                coordinates: entity.geometry.coordinates[0],
                geoJson: entity,
                duration: entity.properties.Duration ?? (description ? description.Duration : null),
                mileage: entity.properties?.Mileage ?? (description ? description.Mileage : null),
                name: entity.properties.Name,
                trail_type: tripType,
            }});

            setTrails(t => [
                ...t,
                newTrail
            ]);
        }
    };

    const VisuallyHiddenInput = styled('input')({
        /*clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,*/
      });

    //Load existing data on initial page load
    useEffect(() => {
        loadExistingData();
    }, []);

    //Kick off the import processing and cycle through each entity
    useEffect(() => {
        if (entitiesToImport && entitiesToImport.length) {
            processEntity(entitiesToImport[0]);
        }
    }, [entitiesToImport]);

    const cleanJsonString = (entity) => {
        // strip any new line characters
        entity = entity.replace(/(\r\n|\n|\r)/gm, '');

        // replace any .} with }, to fix json parsing
        entity = entity.replace(/\.}/g, '}');

        // replace any " ." with ".", to fix json parsing
        entity = entity.replace(/ \./g, '.');

        // replace any }\"" with }", to fix json parsing
        entity = entity.replace(/}\"/g, '}');

        // replace any number.number.number with just the number.number
        entity = entity.replace(/(\d+)\.(\d+)\.(\d+)/g, '$1.$2');

        // replace any ": .number" with ": 0" and catch ":.number" as well
        entity = entity.replace(/: \.(\d+)/g, ': 0');

        // replace any ":00" or ": 00" with ":0" (any number of 0s more than 1), catch with or without space preceeding
        entity = entity.replace(/: ?0+/g, ':0');

        // replace any "{ with {, to fix json parsing
        entity = entity.replace(/"\\{/g, '{');

        return entity;
    }

    return importedEntities.length ? (
        <>
            <Title title="Import" />
            <Stack spacing={4} sx={{ mb: 4 }}>
                <Typography variant="h2" sx={{ fontSize: 24 }}>Imported Data</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Trip Type</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {importedEntities.map((entity, index) => (
                                <TableRow key={index}>
                                    <TableCell>{ entity.type.charAt(0).toUpperCase() + entity.type.substr(1) }</TableCell>
                                    <TableCell>{ entity.trail_type ?? entity.trip_type }</TableCell>
                                    <TableCell>{ entity.name }</TableCell>
                                    <TableCell>{ entity.description }</TableCell>
                                    <TableCell>
                                        <Chip
                                            label={ entity.status.charAt(0).toUpperCase() + entity.status.substr(1) }
                                            size="small"
                                            sx={{
                                                backgroundColor: entity.status === 'imported' ? 'success.main' : 'notice.main',
                                                color: '#FFFFFF',
                                            }}
                                            />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    ) : (
        <>
            <Title title="Import" />
            <Stack spacing={4}>
                <Typography sx={{ textAlign: 'center' }}>Use this tool to import additional trails and locations. Duplicate data will be ignored.</Typography>
                <Form>
                    <SelectInput source="type" choices={[
                        { id: 'snowmobile', name: 'Snowmobile' },
                        { id: 'atv', name: 'ATV' },
                    ]} name="trail_type" required onChange={(event) => setTripType(event.target.value)}/>
                    <FileField source="csvFile" sx={{ borderRadius: 2, border: '2px dashed crimson', width: '50%', mx: 'auto', display: 'block' }}>
                        <FileInput id="import-file" source="" onChange={readFile} inputProps={{ 'aria-label': 'Import file' }} />
                    </FileField>
                </Form>
            </Stack>
        </>
    )
};

export default Import;