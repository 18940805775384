
import './MapPointInput.css';
import React, { useRef, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import mapboxgl from 'mapbox-gl';
import { Typography } from '@mui/material';

mapboxgl.accessToken = 'pk.eyJ1IjoiamFrZS10cngiLCJhIjoiY2wwanN2aWk0MDAwazNqbGRkMDI3dmgzciJ9.bYFoYAI8puR_ozY72G0pEg';

export default function MapPointInput (props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);

    //Allow overriding of the magic form behavior to support updates on map click.
    const { setValue } = useFormContext();

    const latitude = useController({ name: props.latitude, defaultValue: "" });
    const longitude = useController({ name: props.longitude, defaultValue: "" });

    //If there's no point set, center the map on the middle of Maine.
    const defaultMapCenterLatitude = 44.8;
    const defaultMapCenterLongitude = -68.8;

    //Update the position of the marker on the map.
    const placeMarker = (latitude, longitude) => {
        if (!marker.current) {
            marker.current = new mapboxgl.Marker({ color: '#DC143C' }).setLngLat([longitude, latitude]).addTo(map.current);
        }
        marker.current.setLngLat([longitude, latitude]);
    };

    //Initializes the map 
    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/jake-trx/claj2g5oa002h14pqftnuf819',
            center: [
                longitude.field.value === '' ? defaultMapCenterLongitude : longitude.field.value,
                latitude.field.value === '' ? defaultMapCenterLatitude : latitude.field.value,
            ],
            zoom: 6,
        }, []);

        map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        if (latitude.field.value && longitude.field.value) {
            placeMarker(latitude.field.value, longitude.field.value);
        }
        
        //Place (or reposition) the marker when the map is clicked.
        map.current.on('click', (e) => {
            placeMarker(e.lngLat.lat, e.lngLat.lng);

            setValue('latitude', e.lngLat.lat, { shouldDirty: true });
            setValue('longitude', e.lngLat.lng, { shouldDirty: true });
        });
    }, []);

    return (
        <>
            <label>
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.8em' }}>{ props.label ?? 'Location' }</Typography>
            </label>
            <div ref={mapContainer} id="MapPointInput"></div>
            <input type="hidden" {...latitude.field} />
            <input type="hidden" {...longitude.field} />
        </>
    );
}