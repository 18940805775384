import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import { useAuth } from '../../firebase/auth';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

const Trails = () => {
    const { authUser } = useAuth();
    
    const [trails, setTrails] = useState([]);
    const [hasLoadedTrails, setHasLoadedTrails] = useState(false);

    //The trail currently being updated
    const [currentTrail, setCurrentTrail] = useState(null);
    
    const [dialogOpen, setDialogOpen] = useState(false);
    
    const dataProvider = FirebaseRealtimeDatabaseProvider();

    //Column definitions for the data grid
    const columns = [
        { 
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Stack spacing={1} direction="row">
                    <Typography>{params.value}</Typography>
                    { params.row.status === 'inactive' ?
                        <Chip label={params.row.status} size="small" sx={{ textTransform: 'capitalize' }} /> : null
                    }
                </Stack>
            )
        },
        { 
            field: 'grooming_status', 
            headerName: 'Grooming Status',
            flex: 0.5,
            renderCell: (params) => (
                <Typography sx={{ textTransform: 'capitalize' }}>{params.value}</Typography>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <Button startIcon={<EditIcon />} onClick={() => showTrailDialog(params.row)}>Update</Button>
            )
        }
    ];

    //Column definitions for the data grid on mobile
    const mobileColumns = [
        { 
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Stack>
                    <Stack spacing={1} direction="row">
                        <Typography>{params.value}</Typography>
                        { params.row.status === 'inactive' ?
                            <Chip label={params.row.status} size="small" sx={{ textTransform: 'capitalize' }} /> : null
                        }
                    </Stack>
                    { params.row.grooming_status ?
                        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}><strong>Grooming status:</strong> {params.row.grooming_status}</Typography> :
                        null
                    }
                </Stack>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <Button startIcon={<EditIcon />} onClick={() => showTrailDialog(params.row)}>Update</Button>
            )
        }
    ];

    //Launch the dialog and initialize the trail data
    const showTrailDialog = (trail) => {
        setCurrentTrail(trail);
        setDialogOpen(true);
    };

    //Save the updates to the database and the internal array
    const updateTrail = () => {
        dataProvider.update('trails', { id: currentTrail.id, data: currentTrail })
            .then((result) => {
                const updatedTrails = trails.filter((trail) => (trail.id !== currentTrail.id));
                updatedTrails.push(result.data);

                setTrails(updatedTrails);
            });
        setDialogOpen(false);
    }

    //Set the page title
    useEffect(() => {
        document.title = "Your Club's Trails | SledTRX";
    }, []);

    //Load trails once the page is initialized
    useEffect(() => {
        if (!authUser || !authUser.club_ids) return;

        dataProvider.getList('trails')
            .then((result) => {
                setTrails(result.data.filter((trail) => ( trail.club_id && authUser.club_ids.includes(trail.club_id) )));
                setHasLoadedTrails(true);
            });
    }, [authUser]);

    const editTrailDialog = (
        <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="update-dialog-title"
        >
            <DialogTitle id="update-dialog-title">
                Update {currentTrail?.name}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ py: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="trail-status-label">Trail Status</InputLabel>
                        <Select 
                            labelId="trail-status-label"
                            label="Trail Status"
                            value={currentTrail?.status ?? ''}
                            onChange={(e) => setCurrentTrail(ct => ({
                                ...ct,
                                status: e.target.value
                            }))}
                        >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="trail-grooming-status-label">Trail Status</InputLabel>
                        <Select 
                            labelId="trail-grooming-status-label"
                            label="Grooming Status"
                            value={currentTrail?.grooming_status ?? ''}
                            onChange={(e) => setCurrentTrail(ct => ({
                                ...ct,
                                grooming_status: e.target.value
                            }))}
                        >
                            <MenuItem value="groomed">Groomed</MenuItem>
                            <MenuItem value="bumpy">Bumpy</MenuItem>
                            <MenuItem value="closed">Closed</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => updateTrail(false)} autoFocus>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h1" sx={{ fontSize: 36, fontWeight: 'bold' }}>Your Club's Trails</Typography>
                { hasLoadedTrails ?
                    <>
                        <DataGrid
                            columns={columns}
                            rows={trails}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            sx={{ border: 'none', display: { xs: 'none', sm: 'block' }, '.MuiDataGrid-cell:focus-within': { outline: 'none !important' } }}
                        />
                        <DataGrid
                            columns={mobileColumns}
                            rows={trails}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            sx={{ border: 'none', display: { xs: 'block', sm: 'none' }, '.MuiDataGrid-cell:focus-within': { outline: 'none !important' } }}
                        />
                    </> : <CircularProgress />
                }
            </Stack>
            {editTrailDialog}
        </>
    );
};

export default Trails;