import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';

export const GroomingStatusField = () => {
    const record = useRecordContext();
    if (!record || !record.grooming_status) {
        return null;
    }
    return (
        <Typography variant="body2">{record.grooming_status.charAt(0).toUpperCase() + record.grooming_status.substr(1)}</Typography>
    )
};