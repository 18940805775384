import { useEffect, useState } from 'react';
import { Outlet, useOutlet } from 'react-router-dom';
import { useRedirect } from 'react-admin';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';
import NavLayout from '../../components/NavLayout/NavLayout';
import NavLayoutItem from '../../components/NavLayoutItem/NavLayoutItem';
import NavLayoutSection from '../../components/NavLayoutSection/NavLayoutSection';

const LocalHub = (props) => {
    const outlet = useOutlet();
    const redirect = useRedirect();

    const [pages, setPages] = useState([]);

    //Used to help find pages that aren't system reserved
    const reservedPages = ['Home', 'LocalReport', 'Backcountry', 'Store', 'Privacy', 'Terms'];

    useEffect(() => {
        //Redirect to the Local Report if the user visited /LocalHub directly
        if (!outlet) {
            redirect('/LocalHub/LocalReport');
        }
        
        const dataProvider = FirebaseRealtimeDatabaseProvider();
        
        dataProvider.getList('pages')
            .then(result => {
                let pages = [];

                result.data.forEach((page) => {
                    if (!reservedPages.includes(page.slug)) {
                        pages.push(page);
                    }
                });

                setPages(pages);
            });
    }, []);

    const LocalHubNav = (
        <NavLayoutSection>
            <NavLayoutItem key='LocalReport' to='/LocalHub/LocalReport'>Local Report</NavLayoutItem>
            <NavLayoutItem key='Backcountry' to='/LocalHub/Backcountry'>Backcountry</NavLayoutItem>
            {/* <NavLayoutItem key='Store' to='/LocalHub/Store'>Merch Store</NavLayoutItem> */}
            {pages.map((page) => (
                <NavLayoutItem key={page.slug} to={'/LocalHub/'+page.slug}>{page.title}</NavLayoutItem>
            ))}
        </NavLayoutSection>
    );

    return (
        <NavLayout navigation={LocalHubNav}>
            <Outlet />
        </NavLayout>
    );
};

export default LocalHub;