import './Header.css';
import { NavLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { Helmet } from 'react-helmet';

import { useAuth } from '../../firebase/auth';

import Logo from '../../images/Header/SledTRXLogo.png';

import AccountButtons from '../AccountButtons/AccountButtons';

export default function Header() {
    const { authUser } = useAuth();
    const location = useLocation();

    const StyledNavLink = styled(NavLink)(() => ({
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
        borderBottom: 'solid 3px',
        borderColor: 'transparent',

        '&.active': {
            borderColor: 'crimson'
        }
    }));

    const accountActions = (
        authUser ? 
            (
                <>
                    <Stack spacing={1} direction="row" displayPrint="none" sx={{ alignItems: 'center', pr: 2, display: { xs: 'none', sm: 'flex' } }}>
                        <PersonOutlineRoundedIcon />
                        <StyledNavLink to="/Account">
                            Your Account
                        </StyledNavLink>
                    </Stack>
                    <Stack spacing={1} direction="row" displayPrint="none" sx={{ pr: 2, display: { xs: 'flex', sm: 'none' } }}>
                        <StyledNavLink to="/Account">
                            <PersonOutlineRoundedIcon titleAccess="Your Account" />
                        </StyledNavLink>
                    </Stack>
                </>
            ) :
            <Box displayPrint="none" sx={{ pr: 1 }}>
                <AccountButtons includeLogin={true} />
            </Box>
    );

    return (
        <Stack id="HeaderContainer" direction="row" alignItems="center" spacing={1}>
            <NavLink to="/"><img id="LogoImage" src={Logo} alt="SledTRX Logo"></img></NavLink>
            <Box sx={{ flexGrow: 1 }}>
                <Stack id="NavLinks" direction="row" alignItems="center" align="center" spacing={4} displayPrint="none" sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <StyledNavLink to="/"><HomeRoundedIcon titleAccess="Home" /></StyledNavLink>
                    <StyledNavLink to="/Map"><MapOutlinedIcon titleAccess="Explore the Map" /></StyledNavLink>
                    <StyledNavLink to="/LocalHub"><InfoOutlinedIcon titleAccess="Local Hub" /></StyledNavLink>
                </Stack>
                <Stack id="NavLinks" direction="row" alignItems="center" align="center" spacing={8} displayPrint="none" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                    <StyledNavLink to="/">Home</StyledNavLink>
                    <StyledNavLink to="/Map" id="map-nav-button">Explore the Map</StyledNavLink>
                    <StyledNavLink 
                        to="/LocalHub/LocalReport"
                        className={location.pathname.match(/^\/LocalHub/) ? 'active' : ''}
                    >
                        Local Hub
                    </StyledNavLink>
                </Stack>
            </Box>
            {accountActions}
            <Helmet>
                <title>SledTRX Snowmobiling & ATV Service</title>
                <meta name="description" content="SledTRX is a snowmobile mapping service allowing riders to explore news regions and feel secure while out on the trail. Use our website or mobile app to build your personal route for your crew this weekend!" />
                <meta name="keywords" content="snowmobile, snowmobiling, snowmobile trail, snowmobile trails, snowmobile trail map, snowmobile trail maps, snowmobile trail mapping, snowmobile trail mapping service, snowmobile trail mapping services, snowmobile trail app, snowmobile trail apps, snowmobile trail mapping app, snowmobile trail mapping apps, snowmobile trail mapping service app, snowmobile trail mapping service apps, snowmobile trail mapping service application, snowmobile trail mapping service applications, snowmobile trail mapping service software, snowmobile trail mapping service softwares, snowmobile trail mapping software, snowmobile trail mapping softwares, snowmobile trail mapping service software, snowmobile trail mapping service softwares, snowmobile trail mapping service software, snowmobile trail mapping service softwares, snowmobile trail mapping service software, snowmobile trail mapping service softwares, Maine snowmobiling, Maine winter trip planner, Maine snowmbile trip planner, Maine snowmobile rest stops" />
                <meta name="author" content="SledTRX" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
        </Stack>
    );
}