import { Admin, CustomRoutes, Resource, defaultTheme, withLifecycleCallbacks } from 'react-admin';
import { Route } from 'react-router-dom';

import { config, storage } from '../../firebase/firebase';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { AuthProvider as firebaseAuthProvider } from '../../providers/FirebaseAuthProvider';
import firebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';

import { AdminLayout } from '../../components/AdminLayout/AdminLayout';
import AdminLogin from '../AdminLogin/AdminLogin';

import clubs from '../../admin/clubs';
import locations from '../../admin/locations';
import pages from '../../admin/pages';
import trails from '../../admin/trails';
import Import from '../../admin/import/Import';
import Migrations from './Tools/Migrations';

const SledTRXAdmin = () => {
    const authProvider = firebaseAuthProvider(config);
    const dataProvider = firebaseRealtimeDatabaseProvider();

    //Set some theme information based on the SledTRX palette and style.
    const theme = {
        ...defaultTheme,
        palette: {
            primary: {
                main: '#DC143C',
            },
            secondary: {
                main: '#F5F5F5',
            },
            danger: {
                main: '#CC3333',
            },
            success: {
                main: '#33CC33',
            },
            warning: {
                main: '#CC6600',
            },
            notice: {
                main: '#999999',
            }
        },
        typography: {
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },
        components: {
            ...defaultTheme.components,
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        color: '#DC143C',
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'outlined',
                },
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                },
            },
            RaFilterForm: {
                styleOverrides: {
                    root: {
                        minHeight: 'auto !important',
                    },
                },
            },
            RaLayout: {
                styleOverrides: {
                    appFrame: {
                        marginTop: '20rem',
                    },
                },
            },
        },
    };

    //Extend the data provider to handle file uploads
    const dataProviderWithCallbacks = withLifecycleCallbacks(dataProvider, [
        //Update manager relationships for clubs
        {
            resource: 'clubs',
            beforeSave: async (params, dataProvider) => {
                //Get the previous data for the page
                const previousData = await dataProvider.getOne('clubs', { id: params.id })
                    .then((result) => result.data);

                //Grab which managers were added or removed
                const updatedManagerIds = params.manager_ids ? Object.values(params.manager_ids) : [];
                const previousManagerIds = previousData.manager_ids ? Object.values(previousData.manager_ids) : [];
                const addedManagerIds = updatedManagerIds.filter((managerId) => !previousManagerIds.includes(managerId));
                const removedManagerIds = previousManagerIds.filter((managerId) => !updatedManagerIds.includes(managerId));

                //Update user records if there were added managers
                addedManagerIds.forEach((managerId) => {
                    dataProvider.getOne('users', { id: managerId })
                        .then((result) => {
                            let data = result.data;
                            if (!data.club_ids) {
                                data.club_ids = {};
                            }
                            data.club_ids[params.id] = params.id;
                            dataProvider.update('users', { id: managerId, data: data });
                        });
                });

                //Update user records if any managers were removed
                removedManagerIds.forEach((managerId) => {
                    dataProvider.getOne('users', { id: managerId })
                        .then((result) => {
                            if (result.data.club_ids) {
                                const clubIds = result.data.club_ids;
                                clubIds[params.id] = null;

                                const data = {
                                    ...result.data,
                                    'club_ids': clubIds
                                };
                                dataProvider.update('users', { id: managerId, data: data });
                            }
                        })
                });

                //Reformat manager ids on the club record so it's available 
                //to use as a lookup for security rules
                if (params.manager_ids?.length) {
                    const managerIdsValue = {};
                    params.manager_ids.forEach((managerId) => { managerIdsValue[managerId] = managerId });
                    params.manager_ids = managerIdsValue;
                }
                
                return params;
            },
            beforeDelete: async (params, dataProvider) => {
                const previousData = params.previousData;

                //If there were previously managers for the club, remove the club from the user record
                console.dir(previousData);
                if (previousData?.manager_ids) {
                    Object.values(previousData.manager_ids).forEach((managerId) => {
                        dataProvider.getOne('users', { id: managerId })
                            .then((result) => {
                                if (result.data.club_ids) {
                                    const clubIds = result.data.club_ids;
                                    clubIds[params.id] = null;

                                    const data = {
                                        ...result.data,
                                        'club_ids': clubIds
                                    };
                                    dataProvider.update('users', { id: managerId, data: data });
                                }
                            })
                    });
                }

                return params;
            }
        },

        //Handle file uploads for pages
        {
            resource: 'pages',
            beforeSave: async (params, dataProvider) => {
                //Get the previous data for the page
                const previousData = await dataProvider.getOne('pages', { id: params.id })
                    .then((result) => result.data);

                //If a previous file existed, delete it from storage
                if (previousData?.header_photo) {
                    const previousStorageRef = ref(storage, previousData.header_photo.ref);
                    deleteObject(previousStorageRef);
                }
                
                let headerPhotoValue = '';

                let updatedParams = params;

                if (params.header_photo?.rawFile) {
                    const fileExtensionLookup = {
                        'image/jpeg': 'jpg',
                        'image/jpg': 'jpg',
                        'image/png': 'png'
                    };
                    
                    const storageFilename = `${params.slug}.${fileExtensionLookup[params.header_photo.rawFile.type]}`;
                    const storagePath = `headers/${storageFilename}`;

                    //Create a reference to the location to store the file
                    const storageRef = ref(storage, storagePath);

                    //Upload the file and then grab the URL to download
                    const downloadURL = await uploadBytes(storageRef, params.header_photo.rawFile)
                        .then((result) => getDownloadURL(result.ref));

                    headerPhotoValue = {
                        ref: storagePath,
                        src: downloadURL,
                        title: storageFilename
                    };

                    updatedParams = {
                        ...params,
                        header_photo: headerPhotoValue
                    }
                }
                
                return updatedParams;
            },
            beforeDelete: async (params, dataProvider) => {
                const previousData = params.previousData;

                //If a previous file existed, delete it from storage
                if (previousData.header_photo) {
                    const previousStorageRef = ref(storage, previousData.header_photo.ref);
                    deleteObject(previousStorageRef);
                }

                return params;
            }
        }
    ]);

    return (
        <Admin 
            basename="/admin" 
            layout={AdminLayout} 
            authProvider={authProvider}
            dataProvider={dataProviderWithCallbacks} 
            loginPage={AdminLogin}
            theme={theme}
            requireAuth
        >
            <Resource name="trails" {...trails} />
            <Resource name="locations" {...locations} />
            <Resource name="clubs" {...clubs} />
            <Resource name="pages" {...pages} />
            <CustomRoutes>
                <Route path="/import" element={<Import />} />
                <Route path="/migrations" element={<Migrations />} />
            </CustomRoutes>
        </Admin>
    );
};

export default SledTRXAdmin;